#loader {
    background-color: rgba(27, 28, 27, 0.5);
}
.yarl__container {
    background-color: rgba(27, 28, 27, 0.95) !important;
}
.loader-content{
    display: inline-block;
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -24px;
    margin-top: -24px;
}
.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: #00a29b;
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #333333;
    animation: rotate_loader 0.6s linear infinite;
}
  
@keyframes rotate_loader {
    0% {
        transform: rotate(0); }
    100% {
        transform: rotate(360deg);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}
.fade-out {
    animation: fadeOut 0.5s ease-in-out;
}
.slide-up {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}
.auto-height {
    height: auto;
    transition: height 0.5s ease-in-out;
}
.bg-black-shape{
    background-color: var(--secondary-color);
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.dropzone .dz-preview.dz-image-preview {
    background: transparent;
}
.dropzone .dz-preview .dz-remove{
    width: 28px;
    height: 28px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1000;
}
.dropzone .dz-preview .dz-remove .btn,
.dropzone .dz-preview .dz-remove i{
    cursor: pointer !important;
}
.dropzone .dz-preview .dz-image img {
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.pickr .pcr-button {
    height: 3em;
    width: 6em;
}
.pickr .pcr-button::before,
.pickr .pcr-button::after {
    border-radius: 8px;
}

#elements .card-header,
#products .card-header,
#modules .card-header,
.list-group-order {
  touch-action: none;
  cursor: move;
}

.image-uploaded {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transition: all .3s ease;
}
.image-uploaded:hover{
    opacity: 1;
}
.image-uploaded img{
    border: 2px dashed #6d6d6d;
    background-color: #2d2d2d;
    border-radius: 10px;
}


.image-element {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transition: all .3s ease;
}
.image-element:hover{
    opacity: 1;
}
.image-element img{
    border: 2px dashed #6d6d6d;
    background-color: #2d2d2d;
    border-radius: 10px;
}
.image-element .content_icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
.image-element .content_icon i.ri-add-box-fill {
    font-size:40px;
    color: #6d6d6d;
    pointer-events: none;
}
.image-element.content_icon_1{
    display: block;
}
.image-element .content_icon_2{
    display: none;
}
.image-element.div-loader .content_icon_1{
    display: none;
}
.image-element.div-loader .content_icon_2{
    display: block;
}
.image-uploaded .content_icon_1, 
.image-uploaded .content_icon_2{
    display: none;
}

.form-switch label,
.form-switch input{
    cursor: pointer;
}
.swal2-icon.swal2-success .swal2-success-fix {
    top: 0.4em;
}

.swal2-container .swal2-html-container {
    font-size: 1rem;
}

.input-group-text{
    line-height: 1;
}

.image-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.form-loader{
    position: relative;
}
.input-loader {
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 5px;
}
.div-loader {
    pointer-events: none;
}
.input-loader .loading {
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
}
.input-loader i {
    -webkit-animation: loader 1000ms infinite linear;
    animation: loader 1000ms infinite linear;
    position: absolute !important;
}
.card.custom-card .card-header .card-title {
    text-transform: none;
}
.card-body nav ul{
    margin-top: 1rem;
    margin-bottom: 0;
}

.image {
    position: relative;
    height: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: #fff;
    font-weight: 500;
}
.image img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}
.image.image-xs {
    height: 1.25rem;
}
.image.image-sm {
    height: 1.75rem;
}
.image.image-md {
    height: 2.5rem;
}
.image.image-lg {
    height: 3rem;
}
.image.image-xl {
    height: 4rem;
}
.image.image-xxl {
    height: 5rem;
}
.imagetext {
    width: 12rem;
    height: 12rem;
}
.imagetext img {
    width: 12rem;
    height: 12rem;
    border-radius: 10px;
}
.text-muted-2{
    color: #777;
}

.cke_dialog_background_cover{
    background-color: rgba(27, 28, 27, 0.5) !important;
    opacity: 1 !important;
}
.cke_editor_text {
    border: 1px solid #3b3c3b !important;
    border-top: 0 !important;
    border-radius: 0 0 6px 6px !important;
}
.cke_inner{
    border-radius: 0 0 6px 6px !important;
    background: #1b1d1b;
}

a.cke_path_item:hover,
a.cke_path_item:focus,
a.cke_path_item:active {
    background-color: #495457 !important;
}

.video-container{
    position: relative;
    margin-top: 2rem;
}
.video-container .remove-video{
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
}
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #000;
    border-radius: 16px;
}
.embed-container iframe, .embed-container object, .embed-container embed, .embed-container video {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.input-group .btn {
    padding: .4rem .85rem;
}

.card-scroll {
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
}
.card-scroll-products{
    max-height: 456px;
    min-height: 456px;
    overflow-y: auto;
}
.card-hover{
    transition: all .2s linear;
}
.card-hover:hover{
    transform: scale(1.04);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
    background-color: #232628 !important;
}

.form-primary {
  border: 1px solid rgb(0, 162, 155);
}
.form-secondary {
  border: 1px solid rgb(35, 183, 229);
}
.form-warning {
  border: 1px solid rgb(245, 184, 73);
}
.form-info {
  border: 1px solid rgb(73, 182, 245);
}
.form-success {
  border: 1px solid rgb(38, 191, 148);
}
.form-danger {
  border: 1px solid rgb(230, 83, 60);
}
.page-wrapper{
    display: flex;
    flex-direction: column;
}
.header-container{
    background-color: var(--secondary-color);
}
.header-video{
    position: relative;
    width: auto;
    margin-bottom: 100px;
    pointer-events: none;
    z-index: 1;
}
.header-video img{
    height: 150px;
    margin-left: 10%;
    position: absolute;
    bottom: 120px;
    z-index: 2;
}
.no-radius{
    border-radius: 0 !important;
    pointer-events: none;
}
.header-app{
    margin-top: -100px;
    padding: 250px 0 150px 0;
    min-height: 500px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/bg-2.png');
        background-size: cover;
        opacity: 0.4;
        z-index: 0;
    }
}
.header-app .app-container{
    z-index: 1;
    position: relative;
    margin: 0 60px;
}
.header-app .app-img{
    width: 100%;
    margin-right: -50px;
}
.app-container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 38px;
    margin-left: -50px;
}
.app-container-text h2{
    font-size: 38px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    text-align: right;
}
.app-container-text p{
    font-size: 26px;
    letter-spacing: 1px;
    font-family: 'Shoika Thin';
    text-align: right;
}
.app-container-text span{
    color: var(--primary-color);
    font-size: 32px;
    font-family: 'Shoika Bold';
}
.app-icons{
    color: var(--custom-white);
    font-size: 64px;
    text-align: right;
}
.app-icons svg{
    margin-left: 10px;
}

.content-posts {
    padding: 100px 0 0 0;
}
.content-posts h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
}
.content-gym{
    position: relative;
}
.content-gym .gym-space{
    padding: 120px 0;
}
.page-content h4{
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
    padding: 60px 0;
}
.gym-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    position: relative;
    padding: 0;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/bg.jpg');
        background-size: cover;
        z-index: -1;
    }
}
.gym-container-img {
    display: flex;
    z-index: 1;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/gym.jpg');
        background-size: cover;
        background-position: center;
        z-index: -1;
    }
}
.gym-container-text h2{
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
    margin-bottom: 30px;
}
.gym-container-text p{
    font-size: 24px;
    letter-spacing: 1px;
    color: var(--custom-white);
    font-family: 'Shoika Thin';
    text-align: center;
    font-weight: 600;
}
.gym-container-text p:last-child{
    margin-bottom: 0;
}
.gym-container-text span{
    color: var(--custom-black);
}
.content-videos {
    padding: 100px 0 0 0;
}
.content-videos h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
}
.container-video{
    margin: 40px 80px;
}
.video-thumb{
    cursor: pointer;
    display: block;
    border-radius: 12px;
    background-color: #000;
    position: relative;
}
.video-thumb img{
    border-radius: 12px;
    transition: all .2s linear;
}
.video-thumb:hover img{
    opacity: 0.8;
}
.video-thumb.current-video img{
    opacity: 0.4;
}
.video-play{
    display: none;
}
.video-thumb.current-video .video-play{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    color: var(--custom-white);
    font-size: 40px;
    z-index: 2;
}
.carousel-videos{ 
    margin: 30px 0px;
}
.carousel-videos .btn-prev,
.carousel-videos .btn-next {
    opacity: 0.5;
}
.carousel-videos:hover .btn-prev,
.carousel-videos:hover .btn-next {
    opacity: 1;
}

.content-news {
    padding: 100px 0 40px 0;
}
.content-news h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
}
.news-thumb{
    cursor: pointer;
    display: block;
    border-radius: 12px;
    position: relative;
}
.news-thumb img{
    border-radius: 12px;
    transition: all .2s linear;
    width: 100%;
}
.news-thumb h4{
    font-size: 28px;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    text-align: left;
    margin-top: 15px;
}
.news-thumb p{
    font-size: 18px;
    font-family: var(--default-font-family);
    text-align: left;
}
.news-thumb:hover img{
    opacity: 0.8;
}
.carousel-news{ 
    margin: 30px 0px;
}
.carousel-news .btn-prev,
.carousel-news .btn-next {
    opacity: 0.5;
}
.carousel-news:hover .btn-prev,
.carousel-news:hover .btn-next {
    opacity: 1;
}



.content-branch {
    padding: 100px 0 40px 0;
}
.container-branch{
    padding-top: 80px;
}
.content-branch h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
}
.content-branch h3{
    font-size: 38px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    text-align: center;
}
.branch-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 70px;
    position: relative;
    padding: 40px;
    background-color: var(--primary-color);
    margin-bottom: 100px;
    height: 280px;
}
.branch-box h4{
    font-size: 28px;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
    margin-bottom: 20px;
}
.branch-box p{
    font-size: 22px;
    color: var(--custom-black);
    font-family: 'Shoika Thin';
    text-align: center;
}
.branch-link{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -38px;
    left: 0;
    width: 100%;
}
.branch-icon{
    font-size: 60px;
    background-color: var(--custom-black);
    color: var(--custom-white);
    padding: 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-border{
    display: inline-block;
    font-size: 34px;
    letter-spacing: 1px;
    color: var(--primary-color);
    font-family: 'Shoika Thin';
    border-radius: 10px;
    border: 1px solid var(--custom-white);
    padding: 12px 26px 10px 26px;
}
.btn-border-mini{
    display: inline-block;
    font-size: 22px;
    letter-spacing: 1px;
    color: var(--custom-white);
    font-family: 'Shoika';
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    padding: 12px 26px 10px 26px;
}
.container-partners {
    padding-top: 80px;
}
.partner-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative;
    padding: 0 10px;
    background-color: var(--custom-white);
    margin-bottom: 40px;
}
.partner-box img{
    width: 100%;
}
.pipeline-content h3 {
    font-size: 32px;
}
.pipeline-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.pipeline-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    position: relative;
    padding: 20px;
    background-color: var(--secondary-color);
    width: 120px;
    height: 120px;
    font-size: 40px;
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    text-align: center;
    margin-bottom: 20px;
}
.btn-next,
.btn-prev{
    color: var(--custom-white);
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
	height: 100%;
    position: absolute;
	z-index: 999;
	top: 50%;
	transform: translate(0, -50%);
	overflow: hidden;
	text-align: center;
    cursor: pointer;
    transition: all .2s linear;
}

.btn-next:hover,
.btn-prev:hover {
	color: var(--custom-white);
}
.btn-next {
	right: 0;
}
.btn-prev {
	left: 0;
}
.header-banner{
    pointer-events: none;
    z-index: 1;
}
.header-banner img{
    display: block;
    width: 100%;
}
.header-banner.video {
    margin-top: -50px;
    z-index: 2;
}
.header-banner.negative{
    margin-top: -100px;
}
.header-banner-text{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    z-index: 2;
}
.header-gus{
    pointer-events: none;
    z-index: 1;
}
.header-gus img{
    display: block;
    width: 100%;
}

.banner-text-left{
    width: 38%;
    margin-right: 30px;
}
.banner-text-right{
    margin-top: 150px;
    width: 38%;
    margin-left: 30px;
}

.banner-text-left h2,
.banner-text-right h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    text-align: left;
}
.banner-text-right h2{
    text-align: right;
}
.banner-text-left h4,
.banner-text-right h4{
    font-size: 28px;
    letter-spacing: 1px;
    color: var(--primary-color);
    font-family: 'Shoika Thin';
}
.banner-text-left p,
.banner-text-right p{
    font-size: 26px;
    letter-spacing: 1px;
    font-family: 'Shoika Thin';
    text-align: left;
}
.banner-text-right p{
    text-align: right;
}

.header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 32px;
}
.header-social{
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    height: 90px;
}
.menu-logo img{
    height: 90px;
}
.menu-icon{
    font-size: 14px;
    color: var(--secondary-color);
    background-color: var(--custom-white);
    border-radius: 100px;
    margin: 0 7.5px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-icon:hover,
.menu-icon.active,
.menu-icon.active:hover{
    color: var(--custom-white);
    background-color: var(--primary-color);
}
.header-container-menu{
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    flex-direction: column;
    height: 90px;
}
.header-menu{
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: -8px;
}
.menu-text{
    display: flex;
    align-items: center;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    padding-bottom: 4px;
}
.menu-item-icon{
    font-size: 14px;
    color: var(--custom-white);
    margin-left: 6px;
    margin-top: -2px;
}
.menu-item-text{
    font-size: 14px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: var(--custom-white);
    font-family: var(--default-font-family);
}
.menu-text:hover .menu-item-text,
.menu-text.active .menu-item-text,
.menu-text.active:hover .menu-item-text,
.menu-text:hover .menu-item-icon,
.menu-text.active .menu-item-icon,
.menu-text.active:hover .menu-item-icon{
    color: var(--primary-color);
}

.menu-language-digital{
    display: flex;
    align-items: center;
    justify-content: end;
}
.menu-language{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-language-logo img{
    height: 24px;
    margin-left: 12px;
}

.menu-digital{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    background-color: var(--custom-white);
    border-radius: 6px;
    padding: 6px 12px;
    margin-left: 20px;
}
.menu-digital-logo img{
    height: 14px;
    margin-right: 10px;
}
.menu-digital-text{
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-family: 'Shoika Bold';
    height: 18px;
}
.menu-digital:hover .menu-digital-text,
.menu-digital.active .menu-digital-text,
.menu-digital.active:hover .menu-digital-text{
    color: var(--primary-color);
}

.menu-link{
    color: var(--custom-white);
    font-family: 'Shoika Thin';
    font-size: 18px;
    font-weight: 300;
    margin: 6px 0;
}
.menu-footer-link{
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    line-height: 10px;
    cursor: pointer;
}
.menu-ul{
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 10;
    width: 190px;
    padding: 14px;
    border-radius: 10px;
}
.menu-ul a{
    font-size: 16px;
    letter-spacing: 1.2px;
    color: var(--custom-white);
    font-family: var(--default-font-family);
    margin-bottom: 10px;
}
.menu-ul a:last-child{
    margin-bottom: 0px;
}
.menu-ul a:hover{
    color: var(--primary-color);
}
.menu-text:hover .menu-ul{
    display: flex;;
}
.form-check-label{
    line-height: 24px;
}

.footer-container{
    pointer-events: none;
    z-index: 1;
    padding-bottom: 160px;
}
.footer-container img{
    display: block;
    width: 100%;
}


.content-container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.social-content .img-fluid{
    width: 100%;
}
.text-justify{
    text-align: justify;
}
.social-content p:last-child{
    margin-bottom: 0;
}
.content-text-program p{
    font-size: .813rem;
    font-family: var(--default-font-family);
    font-weight: var(--default-font-weight);
    color: var(--default-text-color);
    margin-bottom: 8px;
}
.content-text-program p:last-child{
    margin-bottom: 0;
}
.link-like{
    cursor: pointer;
}

.fc-button {
    transition: all .2s linear;
    padding: 0.5em 0.65em 0.3em 0.65em !important;
}
.fc-button-active,
.fc-button-active:hover,
.fc-button:hover {
    background-color: #03968f !important;
    border-color: #03968f !important;
}
.fc-prev-button,
.fc-next-button {
    padding: 0.4em 0.65em !important;
}
.fc-h-event {
    transition: all .2s linear;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 3px 2px 1px 2px;
    cursor: pointer;
    font-size: 13px;
}
.fc-h-event:hover {
    background-color: #03968f;
    border: 1px solid #03968f;
}

.badge {
    padding: .35rem .45rem .25rem .45rem;
}

.btn-play {
    color: var(--primary-color);
    font-size: 16px;
    margin-left: 15px;
    cursor: pointer;
    margin-top: -3px;
}
.btn-play:hover {
    color: #fff;
}

.btn-custom{
    transition: all .2s linear;
}
.btn-custom:hover{
    opacity: 0.9;
    text-decoration: none !important;
}
.bg-subtitle {
    display: inline-block;
    padding: 6px 14px 4px 14px;
    border-radius: 6px;
    color: #fff;
    margin-right: 10px;
}
.image-content ,
.image-content-hover {
    position: relative;
}
.image-content img {
    width: 100%;
    border-radius: 14px;
    cursor: pointer;
    transition: all .2s linear;
}
.image-content img:hover{
    opacity: 0.8;
    transform: scale(1.04);
}

.image-content-hover {
    cursor: pointer;
    transition: all .2s linear;
}
.image-content-hover img {
    width: 100%;
    border-radius: 14px;
    transition: all .2s linear;
}
.image-content-hover:hover img{
    opacity: 0.8;
}
.btn-download{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px 10px 12px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    font-size: 14px;
    z-index: 2;
    cursor: pointer;
    right: 18px;
    bottom: 6px;
    transition: all .2s linear;
}
.btn-download:hover{
    opacity: 0.8;
}
.btn-download span{
    height: 16px;
}
.btn-download svg{
   font-size: 16px;
   margin-right: 6px;
}
.footer-container{
    position: relative;
}

.footer-all{
    position: absolute;
    width: 100%;
    height: 100%;
}

.footer-newsletter{
    position: absolute;
    top: 24%;
    padding: 0 120px;
}

.footer-newsletter-text{
    font-size: 26px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    text-align: left;
}
.footer-newsletter-form input,
.footer-newsletter-form input:focus,
.footer-newsletter-form input:active{
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 100px;
    padding: 10px 20px;
    width: 100%;
    margin-top: 20px;
}
.footer-newsletter-form a{
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    font-family: var(--secondary-font-family);
    text-align: right !important;
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    margin-left: -4px;
}

.footer-bottom{
    position: absolute;
    width: 100%;
    bottom: 120px;
    padding: 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -30px;
}
.footer-menu div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 80px;
}
.footer-menu div a{
    display: inline-block;
    margin: 10px 0;
    font-size: 16px;
}
.footer-logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.footer-logo-container .menu-logo img{
    width: auto;
    height: 60px;
}

.footer-copyright{
    padding: 0 120px;
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0c0c0c;
}

.logo-nerdetcetera{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logo-nerdetcetera img{
    width: auto;
    height: 32px;
    margin-left: 8px;
}

.content-section h1,
.content-section h2,
.content-section h3,
.content-section h4,
.content-section h5,
.content-section h6{
    text-align: center;
    margin-bottom: 40px;
}

.content-section{
    padding: 120px 0;
}

.content-section.section-1{
    padding: 20px 0;
}
.content-section.section-1 h2{
    color: #fff;
    font-family: 'Shoika Bold';
}
.content-shape-top,
.content-shape-bottom{
    width: 100%;
}
.content-shape-top{
    margin-bottom: -2px;
}
.content-shape-bottom{
    margin-top: -2px;
}
.content-shape-top img,
.content-shape-bottom img{
    width: 100%;
    max-width: 100%;
    height: auto;
}
.content-section .content-box-why div{
    border: 2px solid #3d3d3e;
    padding: 12px 18px;
    margin-bottom: 20px;
    border-radius: 8px;
    transition: all .2s ease;
}
.content-section .content-box-why div:hover{
    transform: scale(1.02);
}
.content-section .content-box-why p{
    font-size: 16px;
    margin-bottom: 0;
}
.content-section .content-box-why svg{
    color: #fff;
    font-size: 38px;
    margin-bottom: 10px;
}


.content-section.section-2 .row{
    background-color: #fff;   
}
.content-section.section-2 h2{
    color: #0c0c0c;
    font-family: 'Shoika Bold';
}
.content-section.section-2{
    color: #3d3d3e;
}
.content-section.section-2 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.content-section.section-2 a{
    color: #00a29b;
    font-family: 'Shoika Bold';
}
.content-section.section-2 a:hover{
    color: #038a83;
}



.content-section.section-3{
    padding: 20px 0;
}
.content-section.section-3 h2{
    color: #fff;
    font-family: 'Shoika Bold';
}
.content-section.section-3{
    color: #dadade;
}
.content-section.section-3 img{
    width: 100%;
    height: auto;
    margin-top: -60px;
}
.content-section.section-3 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}
.content-section.section-4{
    padding-bottom: 0;   
}
.content-section.section-4 .row{
    background-color: #fff; 
    padding: 0 30px;
}
.content-section.section-4 h2{
    color: #00a29b;
    font-family: 'Shoika Bold';
    text-align: left;
    font-size: 34px;
    margin-bottom: 20px;
}
.content-section.section-4{
    color: #3d3d3e;
}
.content-section.section-4 img{
    width: 100%;
    height: auto;
}
.content-section.section-4 p{
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
}
.content-section.section-5{
    padding: 0;
    color: #fff;
}
.content-section.section-5 .row{
    background-color: #00a29b; 
}
.content-section.section-5 h2{
    color: #fff;
    font-family: 'Shoika Bold';
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
}
.content-section.section-5 .row-first{
    padding-top: 60px;
    padding-bottom: 60px;
}
.content-section.section-5 .row-last{
    padding-bottom: 60px;
}
.content-section.section-5 img{
    width: 100%;
    height: auto;
}
.content-section.section-5 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}
.section-5-title{
    font-size: 18px;
    color: #fff;
    font-family: 'Shoika Bold';
}

.section-5-box{
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 8px 12px;
    border-radius: 6px;
    transition: all .2s ease;
}
.section-5-box:hover{
    transform: scale(1.02);
}

.content-section.section-6 h2{
    color: #fff;
    font-family: 'Shoika Bold';
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
}
.content-section.section-6 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 60px;
}

.content-section.section-7 .row{
    background-color: #fff;   
}
.content-section.section-7 h2{
    color: #0c0c0c;
    font-family: 'Monument';
    margin-top: 60px;
}
.content-section.section-7 h2 span{
    color: #00a29b; 
}
.content-section.section-7{
    color: #3d3d3e;
    padding: 20px 0 120px 0;
}
.content-section.section-7 p{
    font-size: 20px;
    text-align: justify;
    margin-bottom: 20px;
}

.content-section.section-8 .row{
    background-color: #fff;
    padding-bottom: 40px;
}
.content-section.section-8 h2{
    color: #0c0c0c;
    font-family: 'Monument';
    margin-top: 60px;
}
.content-section.section-8 h2 span{
    color: #00a29b; 
}
.content-section.section-8{
    color: #3d3d3e;
    padding: 20px 0 0 0;
}
.content-section.section-8 p{
    font-size: 20px;
    text-align: justify;
    margin-bottom: 20px;
}

.content-section.section-9 h2{
    color: #00a29b;
    font-family: 'Monument';
    margin-top: 60px;
}
.content-section.section-9 h2 span{
    color: #00a29b; 
}
.content-section.section-9 .about-icons{
    font-size: 80px;
    margin-bottom: 10px;
}
.content-section.section-9{
    color: #fff;
    padding: 20px 0 60px 0;
}
.content-section.section-9 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}
.content-section.section-9 img{
    width: 100%;
    height: auto;
}

.content-section.section-10 h2{
    color: #0c0c0c;
    font-family: 'Monument';
    margin-top: 60px;
}
.content-section.section-10 h2 span{
    color: #00a29b; 
}
.content-section.section-10{
    background-color: #fff;   
    color: #3d3d3e;
    padding: 60px 0 0 0;
}
.content-section.section-10 p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}
.content-section.section-10 img.img-equipe{
    width: 92%;
    height: auto;
    margin: auto;
    display: flex;
}
.fs-soon{
    font-size: 22px !important;
}

@media (min-width: 1200px) {
    .header-video img {
        height: 150px;
        bottom: 14%;
    }
    .page-wrapper {
        max-width: 1500px;
        margin: auto;
        overflow: hidden;
        background-color: #141414;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .header-video img {
        height: 120px;
        bottom: 14%;
    }
    .menu-item-text {
        font-size: 13px;
        letter-spacing: 1px;
    }
}

@media (max-width: 767px) and (min-width: 481px) {
    .header-video img {
        height: 100px;
        bottom: 14%;
    }
}

@media (max-width: 480px) {
    .header-video img {
        height: 80px;
        bottom: 14%;
    }
}