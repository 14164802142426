.loading-shimmer {
    background-color: #eee;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 1;
    width: 100%;
}
.loading-shimmer.dark {
    background-color: #40403d;
}
.loading-shimmer-animation{
    animation: progress 1.8s linear infinite;
}
  
@keyframes progress {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}