@font-face {
    font-family: 'Monument';
    src: url('../webfonts/MonumentExtended-Regular.eot');
    src: url('../webfonts/MonumentExtended-Regular.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/MonumentExtended-Regular.woff2') format('woff2'),
        url('../webfonts/MonumentExtended-Regular.woff') format('woff'),
        url('../webfonts/MonumentExtended-Regular.ttf') format('truetype'),
        url('../webfonts/MonumentExtended-Regular.svg#MonumentExtended-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Monument Bold';
    src: url('../webfonts/MonumentExtended-Ultrabold.eot');
    src: url('../webfonts/MonumentExtended-Ultrabold.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/MonumentExtended-Ultrabold.woff2') format('woff2'),
        url('../webfonts/MonumentExtended-Ultrabold.woff') format('woff'),
        url('../webfonts/MonumentExtended-Ultrabold.ttf') format('truetype'),
        url('../webfonts/MonumentExtended-Ultrabold.svg#MonumentExtended-Ultrabold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Shoika Black';
    src: url('../webfonts/Shoika-Black.eot');
    src: url('../webfonts/Shoika-Black.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/Shoika-Black.woff2') format('woff2'),
        url('../webfonts/Shoika-Black.woff') format('woff'),
        url('../webfonts/Shoika-Black.ttf') format('truetype'),
        url('../webfonts/Shoika-Black.svg#Shoika-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shoika';
    src: url('../webfonts/Shoika-Regular.eot');
    src: url('../webfonts/Shoika-Regular.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/Shoika-Regular.woff2') format('woff2'),
        url('../webfonts/Shoika-Regular.woff') format('woff'),
        url('../webfonts/Shoika-Regular.ttf') format('truetype'),
        url('../webfonts/Shoika-Regular.svg#Shoika-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shoika Metal';
    src: url('../webfonts/Shoika-Metal.eot');
    src: url('../webfonts/Shoika-Metal.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/Shoika-Metal.woff2') format('woff2'),
        url('../webfonts/Shoika-Metal.woff') format('woff'),
        url('../webfonts/Shoika-Metal.ttf') format('truetype'),
        url('../webfonts/Shoika-Metal.svg#Shoika-Metal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shoika Thin';
    src: url('../webfonts/Shoika-Thin.eot');
    src: url('../webfonts/Shoika-Thin.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/Shoika-Thin.woff2') format('woff2'),
        url('../webfonts/Shoika-Thin.woff') format('woff'),
        url('../webfonts/Shoika-Thin.ttf') format('truetype'),
        url('../webfonts/Shoika-Thin.svg#Shoika-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shoika Bold';
    src: url('../webfonts/Shoika-Bold.eot');
    src: url('../webfonts/Shoika-Bold.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/Shoika-Bold.woff2') format('woff2'),
        url('../webfonts/Shoika-Bold.woff') format('woff'),
        url('../webfonts/Shoika-Bold.ttf') format('truetype'),
        url('../webfonts/Shoika-Bold.svg#Shoika-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}